import axiosInstance from "..";

export async function getPatient() {
  try {
    const response = await axiosInstance.get(`/patient`);
    if (response.status === 200) {
      return response.data?.patient;
    } else throw new Error("Could not get patient", response);
  } catch (err) {
    console.log(err);
  }
}

export async function getPatientByUid(uid) {
  try {
    const response = await axiosInstance.get(`get/patient?uid=${uid}`);
    if (response.status === 200) {
      return response.data;
    } else throw new Error("Could not get patient", response);
  } catch (err) {
    console.log(err);
  }
}

export async function createPatient(patient) {
  try {
    const response = await axiosInstance.put("/patient", patient);
    if (response.status === 200) {
      return response.data;
    } else throw new Error("Could not get patient", response);
  } catch (err) {
    console.log(err);
  }
}

export async function updatePatient(patient) {
  try {
    const response = await axiosInstance.put("/patient", patient);
    if (response.status === 200) {
      return response.data?.patient;
    } else throw new Error("Could not update patient", response);
  } catch (err) {
    console.log(err);
  }
}
